<template>
  <div class="main">
    <div class="modal">
      <div class="modal-left">
        <h2 class="title">Регистрация</h2>
        <div class="bonus-block">
          <div class="bonus-item">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 14C24 19.5228 19.5228 24 14 24C8.47715 24 4 19.5228 4 14C4 8.47715 8.47715 4 14 4C19.5228 4 24 8.47715 24 14ZM14 22C18.4183 22 22 18.4183 22 14C22 9.58172 18.4183 6 14 6C9.58172 6 6 9.58172 6 14C6 18.4183 9.58172 22 14 22ZM14 23C18.9706 23 23 18.9706 23 14C23 9.02944 18.9706 5 14 5C9.02944 5 5 9.02944 5 14C5 18.9706 9.02944 23 14 23ZM13.9055 10H11V19H14.5067C15.2236 19 15.8426 18.8995 16.3635 18.6984C16.889 18.4973 17.292 18.2038 17.5725 17.8181C17.8575 17.4323 18 16.9644 18 16.4145C18 15.9508 17.9132 15.5732 17.7395 15.2818C17.5703 14.9904 17.3365 14.7668 17.0382 14.6108C16.7443 14.4549 16.4081 14.3461 16.0296 14.2845V14.223C16.3947 14.1614 16.702 14.0424 16.9513 13.8659C17.2007 13.6854 17.3899 13.4576 17.5191 13.1826C17.6527 12.9036 17.7195 12.5917 17.7195 12.2469C17.7195 11.422 17.3989 10.8413 16.7576 10.5048C16.1164 10.1683 15.1657 10 13.9055 10ZM14.1393 13.712H12.6031V11.225H13.9924C14.7137 11.225 15.2392 11.3194 15.5687 11.5082C15.9027 11.697 16.0697 12.0048 16.0697 12.4316C16.0697 12.8502 15.9294 13.1683 15.6489 13.3858C15.3683 13.6033 14.8651 13.712 14.1393 13.712ZM12.6031 17.7627V14.9063H14.2128C14.7338 14.9063 15.1479 14.9637 15.4552 15.0787C15.7624 15.1895 15.9828 15.3475 16.1164 15.5527C16.2545 15.7579 16.3235 16 16.3235 16.2791C16.3235 16.7346 16.1721 17.0958 15.8693 17.3625C15.5665 17.6293 15.041 17.7627 14.2929 17.7627H12.6031Z"
                fill="#4891FF"
              ></path>
            </svg>
            <p>125% бонус на первый депозит</p>
          </div>
          <div class="bonus-item">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.0851 7.22495H18.9135L19.4747 6.67124C19.8652 6.28589 19.8652 5.66111 19.4747 5.27576C19.0842 4.89041 18.451 4.89041 18.0605 5.27576L16.0851 7.22495ZM14.6709 7.22495L17.3534 4.57802C18.1344 3.80733 19.4007 3.80733 20.1818 4.57802C20.9159 5.30238 20.96 6.44972 20.3142 7.22495H23C23.5523 7.22495 24 7.67266 24 8.22495V10.1719C24 10.7242 23.5523 11.1719 23 11.1719H17H16.2319H16V7.22495H14.6709ZM17 12.1587L16 12.1587V23.9997H22C22.5523 23.9997 23 23.552 23 22.9997V13.1587C23 12.6064 22.5523 12.1587 22 12.1587L17 12.1587ZM12 7.22495V11.1719H5C4.44772 11.1719 4 10.7242 4 10.1719V8.22495C4 7.67266 4.44772 7.22495 5 7.22495H8.20735C7.64065 6.45339 7.7096 5.3684 8.41421 4.67313C9.19526 3.90243 10.4616 3.90243 11.2426 4.67313L13.8287 7.22495H12.4145L10.5355 5.37086C10.145 4.98551 9.51184 4.98551 9.12132 5.37086C8.7308 5.75621 8.7308 6.38099 9.12132 6.76634L9.58609 7.22495H12ZM12 23.9997V12.1587H6C5.44772 12.1587 5 12.6064 5 13.1587V22.9997C5 23.552 5.44772 23.9997 6 23.9997H12Z"
                fill="#4891FF"
              ></path>
            </svg>
            <p>+250 бесплатные вращения</p>
          </div>
        </div>
        <div class="block-close">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 16 16"
            fill="#9ea8dd"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#9ea8dd"
            ></path>
          </svg>
        </div>
      </div>
      <div class="registration-form modal-right">
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-group" style="width: 100%">
            <input type="tel" id="phone" placeholder="Номер телефона" required v-model="phone" />
          </div>

          <div class="form-group promo-code">
            <input type="text" id="password" placeholder="Пароль" required v-model="password" />
          </div>
          <div class="form-group">
            <select name="currency" id="currency" v-model="currency">
              <option value="RUB" selected>RUB</option>
              <option value="UAH">UAH</option>
            </select>
          </div>
          <div class="form-group promo-code">
            <input type="text" id="promo" placeholder="Промокод" v-model="promocode" />
          </div>
          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" id="terms" checked required />
              <p>
                Я принимаю <a href="#">Условия и положения</a>
              </p>
            </label>
          </div>
          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" id="check" checked required />
              <p>
                Мне есть 18 лет
              </p>
            </label>
          </div>
          <button type="submit" class="submit-btn">Зарегистрироваться</button>
        </form>
        <div class="sign-in">
          <p>Уже есть аккаунт? <a href="#">Войти</a></p>
          <p style="margin-top: 24px;">Не помните пароль<a href="#"> Востановить</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      phone: '+7',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'TOR',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
